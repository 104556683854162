/**
 * Card (<article>)
 */

article {
  margin: var(--block-spacing-vertical) 0;
  padding: var(--block-spacing-vertical) var(--block-spacing-horizontal);
  overflow: hidden;
  border-radius: var(--border-radius);
  background: var(--card-background-color);
  box-shadow: var(--card-box-shadow);

  > header,
  > footer {
    margin-right: calc(var(--block-spacing-horizontal) * -1);
    margin-left: calc(var(--block-spacing-horizontal) * -1);
    padding: calc(var(--block-spacing-vertical) * 0.66)
      var(--block-spacing-horizontal);
    background-color: var(--card-sectionning-background-color);
  }

  > header {
    margin-top: calc(var(--block-spacing-vertical) * -1);
    margin-bottom: var(--block-spacing-vertical);
    border-bottom: var(--border-width) solid var(--card-border-color);
  }

  > footer {
    margin-top: var(--block-spacing-vertical);
    margin-bottom: calc(var(--block-spacing-vertical) * -1);
    border-top: var(--border-width) solid var(--card-border-color);
  }
}
