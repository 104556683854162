/**
 * Nav
 */

// Horizontal Nav
nav,
nav ul {
  display: flex;
}

nav {
  justify-content: space-between;

  ol,
  ul {
    align-items: center;
    margin-bottom: 0;
    padding: 0;
    list-style: none;

    &:first-of-type {
      margin-left: calc(var(--spacing) * -0.5);
    }
    &:last-of-type {
      margin-right: calc(var(--spacing) * -0.5);
    }
  }

  li {
    display: inline-block;
    margin: 0;
    padding: var(--spacing) calc(var(--spacing) * 0.5);

    // HACK: Input & Button inside Nav
    > *,
    > input:not([type="checkbox"]):not([type="radio"]) {
      margin-bottom: 0;
    }
  }

  a {
    display: block;
    margin: calc(var(--spacing) * -1) calc(var(--spacing) * -0.5);
    padding: var(--spacing) calc(var(--spacing) * 0.5);
    border-radius: var(--border-radius);
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }
  }
}

// Vertical Nav
aside {
  nav,
  ol,
  ul,
  li {
    display: block;
  }

  li {
    padding: calc(var(--spacing) * 0.5);

    a {
      margin: calc(var(--spacing) * -0.5);
      padding: calc(var(--spacing) * 0.5);
    }
  }
}
